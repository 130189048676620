import {Component, Inject, isDevMode, OnDestroy, PLATFORM_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {LocalStorageService} from '@core/services/local-storage';
import {BaseStoreService} from '@core/abstract/store.abstract';
import {Observable, Subscription, timer} from 'rxjs';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {OrdersService} from '@core/services/account/orders.service';
import {Meta, Title} from '@angular/platform-browser';
import {LocalStorageKey, Time} from '@core/enums';
import {SharedService} from '@core/services';
import {isPlatformBrowser} from '@angular/common';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  subscriptions$: Subscription[] = [];
  showOverlay = true;

  acceptedCookies: boolean;
  cookieConsentAvailable: boolean;
  showPromoBanner = false;

  loadingMessages: string[] = [
    'Mixing music...',
    'Tuning instruments...',
    'Setting up the stage...',
    'Gathering the band...'
  ];
  message$: Observable<string>;

  // Declare services that are only used in the browser
  private afMessaging?: AngularFireMessaging;
  private gaService?: GoogleAnalyticsService;

  constructor(
    private translateService: TranslateService,
    private baseStoreService: BaseStoreService,
    private localStorageService: LocalStorageService,
    private orderService: OrdersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private title: Title,
    private sharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId: object,
    // Inject services optionally to prevent issues during SSR
    @Inject(AngularFireMessaging) afMessaging?: AngularFireMessaging,
    @Inject(GoogleAnalyticsService) gaService?: GoogleAnalyticsService
  ) {
    if (!isPlatformBrowser(this.platformId))
      return;

    // Assign services only if running in the browser
    this.afMessaging = afMessaging;
    this.gaService = gaService;


    // Initialize client-side specific logic
    this.acceptedCookies = this.localStorageService.getLocalStorageItem(LocalStorageKey.COOKIES_ACCEPTED);
    if (isDevMode())
      console.log('accepted cookies:', this.acceptedCookies);


    this.cookieConsentAvailable = true;

    if (this.acceptedCookies)
      this.acceptedCookies = true;
    else if (this.acceptedCookies === null) {
      this.cookieConsentAvailable = false;
      this.acceptedCookies = false;
    }

    this.listen();
    this.setSEO();

    // Language settings
    translateService.setDefaultLang('en-US');
    translateService.use('en-US');


    // Subscribe to router events regardless of platform
    this.subscriptions$.push(this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    }));

    if (isDevMode())
      console.log('App component reloaded!');


    // Subscribe to sharedService regardless of platform
    this.subscriptions$.push(this.sharedService.showLoadingOverlay.subscribe((show: boolean) => {
      this.showOverlay = show;
    }));

    // Initialize message$ regardless of platform
    this.message$ = timer(0, 3000).pipe(
      map(i => this.loadingMessages[i % this.loadingMessages.length])
    );
  }

  /**
   * Recursively get the deepest child route
   */
  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild)
      return this.getChild(activatedRoute.firstChild);
    else
      return activatedRoute;
  }

  /**
   * Listen to messages only if running in the browser
   */
  listen() {
    if (!isPlatformBrowser(this.platformId)) return;

    if (isDevMode())
      console.log('Started listening to notifications.');


    if (this.afMessaging)
      this.subscriptions$.push(this.afMessaging.messages
        .subscribe((message) => {
          this.orderService.orderCompletedNotification.next();
        })
      );
  }

  /**
   * Intercept router events to manage loading overlay and analytics
   */
  navigationInterceptor(event: RouterEvent): void {
    if (isDevMode())
      console.log('DEBUG:  Navigation interceptor called.');

    if (!event.url) return;

    if (event instanceof NavigationStart)
      this.showOverlay = true;


    if (event instanceof NavigationEnd) {
      let pageTitle = '';
      const rt = this.getChild(this.activatedRoute);
      this.subscriptions$.push(rt.data.subscribe(data => {
        pageTitle = data.title;
      }));

      // Send page view to Google Analytics only in the browser and if GA service is available
      if (isPlatformBrowser(this.platformId) && this.gaService) {
        this.gaService.pageView(event.urlAfterRedirects, pageTitle);
        window.scrollTo(0, 0);
      }

      // Determine if promo banner should be shown
      this.showPromoBanner = event.url.includes('software') || event.url === '/';

      console.log('DEBUG:  Navigation end event. Show promo banner:', this.showPromoBanner);

      this.showOverlay = false;
    }

    // Hide overlay on navigation cancel or error
    if (event instanceof NavigationCancel || event instanceof NavigationError)
      this.showOverlay = false;
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach(x => x.unsubscribe());
  }

  /**
   * Set SEO meta tags and title
   */
  setSEO() {
    // Meta tags are safe to set on both server and browser
    this.meta.addTags([
      {name: 'description', content: 'Origin Audio landing page'},
      {name: 'author', content: 'Origin Audio LLC'},
      {
        name: 'keywords',
        content: 'Sound, Music, Machine Learning, Deep Learning, Consulting, Cloud, IT, Information Technology'
      },
    ]);
    this.title.setTitle('Home - Origin Audio LLC');
  }

  /**
   * Handle cookie consent
   * @param accepted - Whether the user accepted cookies
   */
  async acceptCookies(accepted: boolean) {
    if (!isPlatformBrowser(this.platformId)) return;

    if (accepted) {
      if (isDevMode())
        console.log('Cookies accepted');
      this.acceptedCookies = true;
      this.cookieConsentAvailable = true;
    } else {
      if (isDevMode())
        console.log('Cookies not accepted');
      this.removeGoogleAnalyticsScript();
      this.acceptedCookies = false;
      this.cookieConsentAvailable = true;
    }

    await this.localStorageService.saveLocalStorageItem(
      LocalStorageKey.COOKIES_ACCEPTED,
      this.acceptedCookies,
      30 * Time.Day
    );
  }

  /**
   * Remove Google Analytics script and reset GA functions
   */
  removeGoogleAnalyticsScript() {
    if (!isPlatformBrowser(this.platformId)) return;

    // Remove the Google Analytics script tag
    const script = document.querySelector(`script[src^='https://www.googletagmanager.com/gtag/js']`);
    if (isDevMode())
      console.log('Removing Google Analytics script', script);
    if (script)
      script.remove();

    // Clear any existing tracking data and redefine global functions
    window['dataLayer'] = [];
    window['gtag'] = () => {
      if (isDevMode())
        console.log('Google Analytics is disabled');
    };
  }


  async onKeydown(event: KeyboardEvent, accept: boolean): Promise<void> {
    const key = event.key;

    if (key === 'Enter' || key === ' ') {
      event.preventDefault(); // Prevent default behavior of Enter or Space
      await this.acceptCookies(accept);
    }
  }
}
