<!-- BEGIN COOKIE INFO BAR -->

<div class="container-fluid cookies-infobar_wrapper fixed-bottom" *ngIf="!cookieConsentAvailable" aria-live="polite" aria-atomic="true" role="alert">
  <div class="row">
    <div class="col-12 text-center">
      <p class="cookie-message" tabindex="0">
        We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.
      </p>
      <p class="terms-message" tabindex="0">
        By using this page, you agree to the
        <a href="https://data.originaudio.net/documents/Terms%20of%20Service.pdf" class="terms-link" aria-label="Read our Terms of Service">Terms of Service</a>
        and our
        <a href="https://data.originaudio.net/documents/Privacy%20Policy.pdf" class="privacy-link" aria-label="Read our Privacy Policy">Privacy Policy</a>.
      </p>
      <!-- Added aria-label for Accept/Reject buttons -->
      <a id="cookies-infobar-accept" class="btn oa-btn-primary mx-2 my-2"
         (click)="acceptCookies(true)" tabindex="0" (keydown)="onKeydown($event,true)"
         aria-label="Accept cookies and continue using the site">Accept</a>
      <a id="cookies-infobar-reject" class="btn oa-btn-danger mx-2 my-2"
         (click)="acceptCookies(false)" tabindex="0" (keydown)="onKeydown($event,true)"
         aria-label="Reject cookies and continue using the site">Reject</a>
    </div>
  </div>
</div>

<!-- CLOSE COOKIE INFO BAR -->

<div class="snackbar" id="snackbar" role="status" aria-live="assertive"></div>

<div class="container-fluid px-0 oa-theme-primary-darkest">
  <app-navbar></app-navbar>
  <app-promo-code-banner *ngIf="showPromoBanner"></app-promo-code-banner>

  <!-- Ensure loading bar is accessible with role=status and aria-live -->
  <ngx-loading-bar color="#9d5252" height="4px" [includeSpinner]="false" ref="router" role="status" aria-live="polite"></ngx-loading-bar>
  <ngx-loading-bar color="#9d5252" height="4px" [includeSpinner]="false" ref="http" role="status" aria-live="polite"></ngx-loading-bar>

  <router-outlet></router-outlet>

  <!-- FOOTER -->
  <app-footer></app-footer>
</div>

<!-- Loading Overlay -->
<div id="loadingOverlay" class="overlay" *ngIf="showOverlay">
  <div class="overlay-content">
    <div class="spinner-border text-primary" role="status">
      <!-- Ensure loading message is announced to screen readers -->
      <span class="sr-only">{{ message$ | async }}</span>
    </div>
    <h3 class="mt-3">{{ message$ | async }}</h3>
  </div>
</div>
