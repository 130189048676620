import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {ICountDown} from '@core/model';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-promo-code-banner',
  templateUrl: './promo-code-banner.component.html',
  styleUrls: ['./promo-code-banner.component.scss']
})
export class PromoCodeBannerComponent {
  countDown: ICountDown = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };


  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    if (!isPlatformBrowser(this.platformId)) return;
    this.timer();
  }

  timer() {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const countDownDate = lastDayOfMonth.getTime();

// Update the count down every 1 second
    const x = setInterval(() => {

      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      this.countDown.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.countDown.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.countDown.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.countDown.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        this.countDown.days = 0;
        this.countDown.hours = 0;
        this.countDown.minutes = 0;
        this.countDown.seconds = 0;
      }
    }, 1000);
  }
}
