<div class="container my-3">
  <div class="row">
    <div class="col-md-3 col-sm-12 my-2">
      <app-ad [adSlot]="8362146451" [attr.aria-label]="'Advertisement'"></app-ad>
    </div>

    <div class="col-md-6 my-2 ">
      <div class="card">
        <div class="card-body text-center">
          <h5 class="card-title" aria-label="Special Discount Offer">Special Discount Offer</h5>
          <p class="card-text" aria-label="Use Code OA50 for 50 percent off">Use Code: <strong>OA50</strong> for 50% off!</p>

          <!-- Countdown Timer -->
          <div class="row gx-2 justify-content-center" id="countdown" aria-live="polite" role="timer"
               aria-label="Countdown Timer">
            <div class="col-3">
              <span id="days" class="countdown-number">{{ countDown.days }}</span> Days
            </div>
            <div class="col-3">
              <span id="hours" class="countdown-number">{{ countDown.hours }}</span> Hours
            </div>
            <div class="col-3">
              <span id="minutes" class="countdown-number">{{ countDown.minutes }}</span> Minutes
            </div>
            <div class="col-3">
              <span id="seconds" class="countdown-number">{{ countDown.seconds }}</span> Seconds
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-12 my-2">
      <app-ad [adSlot]="8362146451" [attr.aria-label]="'Advertisement'"></app-ad>
    </div>
  </div>
</div>


