import {NgModule} from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: 'about',
    loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'auto-slicer',
    loadChildren: () => import('./modules/software/auto-slicer/auto-slicer.module').then(m => m.AutoSlicerModule)
  },
  {
    path: 'normalizer',
    loadChildren: () => import('./modules/software/batch-normalizer/batch-normalizer.module').then(m => m.BatchNormalizerModule)
  },
  {
    path: 'stem-isolator',
    loadChildren: () => import('./modules/software/sound-separator/sound-separator.module').then(m => m.SoundSeparatorModule)
  },
  {
    path: 'file-converter',
    loadChildren: () => import('./modules/software/file-converter/file-converter.module').then(m => m.FileConverterModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'newsletter',
    loadChildren: () => import('./modules/newsletter/newsletter.module').then(m => m.NewsletterModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule)
  },
  {
    path: 'loading',
    loadChildren: () => import('./modules/loading-screen/loading-screen.module').then(m => m.LoadingScreenModule)
  },
  {
    path: 'discord',
    loadChildren: () => import('./modules/discord-confirmation/discord-confirmation.module').then(m => m.DiscordConfirmationModule)
  },
  {
    path: 'education',
    loadChildren: () => import('./modules/eduction/eduction.module').then(m => m.EductionModule)
  },
  {
    path: 'refer-a-friend',
    loadChildren: () => import('./modules/refer-a-friend/refer-a-friend.module').then(m => m.ReferAFriendModule)
  },
  {
    path: '**',
    redirectTo: ''
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: NoPreloading,
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
