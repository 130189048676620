import {tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Inject, Injectable, isDevMode, PLATFORM_ID} from '@angular/core';
import {CachingService} from './cache.service';
import {of, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {HttpActionResultCode} from '@core/enums';
import {IAccount} from '@core/model';
import {LocalStorageService} from '@core/services/local-storage';
import {UrlService} from '@core/services/urlService';
import {isPlatformBrowser} from "@angular/common";


const noCacheRoutes: Array<string> = ['authentication'];

@Injectable()
export class CachingInterceptor implements HttpInterceptor {


  constructor(
    private cache: CachingService,
    private baseAccountService: BaseAccountService,
    private router: Router,
    private urlService: UrlService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if(!isPlatformBrowser(this.platformId))
      return of(null);

    if (req.method === 'GET') {
      const cachedResponse = this.cache.get(req);
      return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.cache);
    }

    return this.sendRequest(req, next, this.cache);
  }

  sendRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    cache: CachingService): Observable<HttpEvent<any>> {


    const account: IAccount = this.baseAccountService.getAccount();
    const session = this.baseAccountService.getSession();

    if (isDevMode())
      if (session)
        console.log(`Session: ${session}`);
      else
        console.log('No session found');

    if (!request.headers.has('override'))
      if (!session)
        request = this.createJSONRequestWithoutAuthorization(request);
      else
        request = this.createJSONRequest(request, account, session);


    if (isDevMode())
      console.log(`Request: ${request.url}`);

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        let found = false;
        noCacheRoutes.forEach(x => {
          if (request.url.includes(x))
            found = true;
        });

        if (event instanceof HttpResponse && request.method === 'GET' && !found) {
          if (isDevMode())
            console.log(`Caching: ${request.url}`);
          cache.put(request, event);
        }

      }, (err: any) => {
        if (err instanceof HttpErrorResponse)
          switch (err.status) {

            case HttpActionResultCode.NO_AUTHORIZATION:
              if (this.router.url.includes('signin'))
                return throwError(err);


              if (this.router.url.includes('pricing'))
                return throwError(err);

              this.urlService.redirectUrl = this.router.url;
              this.router.navigateByUrl('authentication/signin').then();

              return throwError(err);

            case HttpActionResultCode.FORBIDDEN:
              return throwError(err);

            case HttpActionResultCode.SERVICE_UNAVAILABLE:
              this.router.navigateByUrl('serviceunavailable').then();
              return throwError(err);

            case 0:
              return throwError(err);

            default:
              return throwError(err);
          }
      }));
  }


  createJSONRequestWithoutAuthorization(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Language: '1',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      }
    });
  }

  createJSONRequest(request: HttpRequest<any>, account: IAccount, session: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${session}`,
        CustomerId: (account && account.customerId) ? account.customerId.toString() : '0',
        Language: (account && account.language) ? account.language.toString() : '1',
        'Content-Type': 'application/json',
      }
    });
  }

  createMultiPartRequest(request: HttpRequest<any>, account: IAccount, session: string) {
    return request.clone({
      setHeaders: {
        Authorization: (account) ? `Bearer ${session}` : null,
        CustomerId: (account && account.customerId) ? account.customerId.toString() : '0',
        Language: (account && account.language) ? account.language.toString() : '1',
      }
    });
  }
}
