<nav class="navbar navbar-expand-lg navbar-light sticky-top oa-navbar oa-nav-transition navbar-text py-4" id="navbar" role="navigation" aria-label="Main Navigation">
  <div class="container">
    <!-- Logo -->
    <div class="mat-h1 mb-0">
      <a routerLink="" aria-label="Origin Audio Home">
        <img ngSrc="/assets/images/logo/logowhitebg.jpg" alt="Origin Audio Logo" width="100" height="50">
      </a>
    </div>

    <!-- Hamburger Menu Button -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            id="hamburger-menu" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Navigation Links -->
    <div class="collapse navbar-collapse px-0" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <!-- Pricing Link -->
        <li class="nav-item px-3">
          <div class="mat-h1 my-auto">
            <a class="nav-link" routerLink="pricing" aria-label="Pricing Page">
              Pricing
            </a>
          </div>
        </li>

        <!-- About Link -->
        <li class="nav-item px-3">
          <div class="mat-h1 my-auto">
            <a class="nav-link" routerLink="about" aria-label="About Origin Audio">
              About
            </a>
          </div>
        </li>

        <!-- Blog Link -->
        <li class="nav-item px-3">
          <div class="mat-h1 my-auto">
            <a class="nav-link" href="https://originaudio.medium.com/" target="_blank" aria-label="Visit Origin Audio Blog">
              Blog
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
