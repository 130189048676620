import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NavbarComponent} from '@modules/shared/ui/navbar/navbar.component';
import {FooterComponent} from '@modules/shared/ui/footer/footer.component';
import {RouterLink} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import { PromoCodeBannerComponent } from './promo-code-banner/promo-code-banner.component';
import {PipesModule} from '@core/pipes/pipes.module';
import {AdsModule} from '@modules/shared/ad/ads.module';


@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    PromoCodeBannerComponent
  ],
    imports: [
        CommonModule,
        RouterLink,
        NgOptimizedImage,
        ReactiveFormsModule,
        PipesModule,
        AdsModule
    ],
    exports: [
        NavbarComponent,
        FooterComponent,
        PromoCodeBannerComponent
    ]
})
export class UiModule {
}
